import moment from "moment";
import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";

import { ArrowBackIcon, CheckMarkIcon } from "../../../../assets";
import { Icon } from "../../../../components/Icon";
import { Input } from "../../../../components/Input/Input";
import { Modal, useModal } from "../../../../components/Modal/Modal.component";
import { AlertsPage } from "../../alerts/layout/AlertsPage";
import { Table, TableRow } from "../../../components/Table/Table";
import { Alert } from "../../../../types/alerts";
import { useAlerts } from "../../alerts/AlertsPageContainer";
import * as Historic from "./historic";
import { MemberEvent } from "../../../../lib/utils/member-event.utils";
import { Chart } from "../../../../lib/utils/chart";
import { Timeline } from "./components";
import EditCattleModal from "./edit";
import { useServices } from "../../../../services/service-providers/service-provider";
import { useHistory } from "react-router-dom";
import NANDI_URL from "../../../../lib/url";
import ActivityChartContainer from "./ActivityChartContainer";
import ActivityChartComponent from "./ActivityChartContainer/ActivityChartComponent";
import DailyCattleJumpBullContainer from "./DailyCattleJumpBullContainer";
import DailyBullJumpsContainer from "./DailyBullJumpsContainer/DailyBullJumps.Container";
import { User, UserRole } from "../../../../types/auth";
import MemberProfilePicture from "./MemberProfilePicture";
import MemberProfilePicturesModal from "./MemberProfilePicturesModal";
import classNames from "classnames";
import CattleInformation from "./CattleInformation";
import { EnhancedMap } from "../../../../components/Map/Map";
import {
  FieldSummaryWithExtraDetails,
  NdviData,
  Property,
} from "../../../../types/fields";
import MainLayoutDelimiter from "../../../components/MainLayoutDelimiter";
import EditCattleModalV2 from "./edit/modal-v2";
import CattleInfoTabs from "../../../components/CattleInfoTabs";
import { TableV2 } from "../../../../components/shadcn/Table/Table";

type CattleMemberPageProps = {
  member: MemberWithDetails;
  alerts?: Array<Alert> | null;
  weightEvents: Array<MemberEvent> | null;
  vaccinationEvents: Array<MemberEvent> | null;
  dailyBullJumps: DailyActivityV2 | null;
  deltaActivityV2: DailyActivity | null;
  deltaActivityV3: DailyActivity | null;
  dailyCattleJumpBull: DailyCattleJumpBull | null;
  paddockAssignmentEvents: Array<MemberEvent> | null;
  sensorPairingEvents: Array<MemberEvent> | null;
  pregnancyEvents: Array<MemberEvent> | null;
  contactWithCowAggrEvents: Array<MemberEvent> | null;
  contactWithBullAggrEvents: Array<MemberEvent> | null;
  timeline: Timeline | null;
  user: User | null;
  currentFieldName: string;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Label: FunctionComponent<{ value: ReactNode; label: ReactNode }> = ({
  value,
  label,
}) => {
  if (!value) {
    return null;
  }
  return (
    <p className="flex flex-col items-center">
      <span className="font-semibold">{value}</span>
      <span className="mt-1 text-gray-500">{label}</span>
    </p>
  );
};

const HistoryButton = ({
  text,
  iconName,
  onClick,
  iconCustomClassName,
}: {
  text?: any;
  iconName: string;
  onClick: () => void;
  iconCustomClassName?: string;
}) => {
  return (
    <>
      <div
        className="shadow p-2 flex flex-col items-center rounded cursor-pointer"
        onClick={onClick}
      >
        <Icon
          className={classNames(
            iconCustomClassName
              ? iconCustomClassName
              : "fill-current text-pink-600"
          )}
          iconName={iconName}
        />
        {text ? <span className="mt-2 hover:text-pink-600">{text}</span> : null}
      </div>
    </>
  );
};

type MapProps = {
  fields: Array<FieldSummaryWithExtraDetails>;
  geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry, Property>;
};

const CattleMemberPage: FunctionComponent<CattleMemberPageProps & MapProps> = ({
  member,
  alerts,
  weightEvents,
  vaccinationEvents,
  deltaActivityV2,
  deltaActivityV3,
  dailyBullJumps,
  dailyCattleJumpBull,
  paddockAssignmentEvents,
  sensorPairingEvents,
  pregnancyEvents,
  contactWithCowAggrEvents,
  contactWithBullAggrEvents,
  timeline,
  user,
  fields,
  geoJsonData,
  currentFieldName,
}) => {
  const [editIsLoading, setEditIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const [chartOnDisplay, setChartOnDisplay] = useState(
    deltaActivityV2 && Object.keys(deltaActivityV2).length > 0
      ? "steps-v3"
      : "timeline"
  );
  const { fieldsService, membersService, storageService } = useServices();
  const [ndviFilter, setNdviFilter] = useState(false);
  const [nvdiData, setNvdiData] = useState<NdviData[]>([]);
  const [profilePicture, setProfilePicture] = useState<string>();
  const [profilePictureFront, setProfilePictureFront] = useState<string>();

  const handleNDVIFilter = () => {
    setNdviFilter((prevNdviFilter) => !prevNdviFilter);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const valoresDeTile = fields.map((tile) => tile.ndvi_tile);
        const tiles = valoresDeTile.join("|");
        setNvdiData(await fieldsService.getNvdiByTile(tiles));
      } catch (error) {
        // Maneja los errores aquí
        console.error("Error al obtener datos:", error);
      }
    };
    fetchData();
  }, []);

  const [selectedInformationOption, setSelectedInformationOption] =
    useState("vaccines");

  const profilePictureModal = useModal();
  const vaccinesHistoryModal = useModal();
  const weightsHistoryModal = useModal();
  const paddockAssignmentModal = useModal();
  const sensorPairingModal = useModal();
  const pregnancyModal = useModal();
  const editModal = useModal();
  const alertsH = useAlerts();
  const handleProfileImageOnClick = () => {
    profilePictureModal.setIsVisible(true);
  };

  const asyncActionWithConfirm = async (
    action: () => Promise<any>,
    confirmLabel: string
  ) => {
    if (user?.role === UserRole.SUPERUSER) {
      if (window.confirm(confirmLabel)) {
        await action()
          .then(() => {
            window.location.reload();
          })
          .catch((err) => {
            throw Error(err);
          });
      }
    }
  };

  const handleRemoveGpsData = async () => {
    await asyncActionWithConfirm(
      () => membersService.removePositionData(member.id),
      t(`admin.cattlePage.attributes.removeLastPosition.confirm`)
    );
  };

  const handleTurnOffAlerts = async () => {
    await asyncActionWithConfirm(
      () => membersService.turnOffAlertsByMemberId(member.id),
      t(`admin.cattlePage.attributes.turnOffAlerts.confirm`)
    );
  };

  const handleTurnOnAlerts = async () => {
    await asyncActionWithConfirm(
      () => membersService.turnOnAlertsByMemberId(member.id),
      t(`admin.cattlePage.attributes.turnOnAlerts.confirm`)
    );
  };

  // refactor from here
  const [expandedEvent, setExpandedEvent] = useState<{
    memberEvent: MemberEvent;
    memberEventAttributes: MemberEventAttribute[];
  } | null>(null);
  const [expandedRow, setExpandedRow] = useState<number>(-1);

  const handleRowOnClose = () => {
    setExpandedEvent(null);
    setExpandedRow(-1);
  };

  const handleRowOnClick = async (mEvent: MemberEvent) => {
    setExpandedRow(mEvent.id);
    setExpandedEvent({
      memberEvent: mEvent,
      memberEventAttributes: mEvent.attributes,
    });
  };

  const handleModalClose = () => {
    setExpandedEvent(null);
    setExpandedRow(-1);
  };

  const handleVaccineModalClose = () => {
    handleModalClose();
    vaccinesHistoryModal.setIsVisible(false);
  };

  const handleWeightModalClose = () => {
    handleModalClose();
    weightsHistoryModal.setIsVisible(false);
  };

  const handlePregnancyModalClose = () => {
    handleModalClose();
    pregnancyModal.setIsVisible(false);
  };

  const handleLoadNewSensor = async (sensorName: string) => {
    setEditIsLoading(true);
    await membersService
      .pairNewSensorToMember(member.id, sensorName, member.account_id)
      .then(() => {
        if (window.confirm("Cambios satisfactorios")) {
          setEditIsLoading(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        if (window.confirm("Algo salió mal, por favor intente más tarde")) {
          setEditIsLoading(false);
        }
        throw new Error(err);
      });
  };
  const handleNext = async () => {
    const requestNextCatlle = await membersService.getNextMember(member.id);
    const nextMemberId = requestNextCatlle.nextMemberId;
    if (nextMemberId) {
      history.push(NANDI_URL.ADMIN.GENERATE_CATTLE_BY_ID(String(nextMemberId)));
    }
  };

  const handleEditMember = async (params: { [key: string]: string }) => {
    setEditIsLoading(true);
    await membersService
      .updateMember(member.id, params)
      .then(() => {
        if (window.confirm("Cambios satisfactorios")) {
          setEditIsLoading(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        if (window.confirm("Algo salió mal, por favor intente más tarde")) {
          setEditIsLoading(false);
        }
        throw new Error(err);
      });
  };

  const loadProfileImage = async () => {
    if (member.profile_picture) {
      const profilePictureSignedUrl = await storageService.get(
        member.profile_picture
      );
      setProfilePicture(profilePictureSignedUrl);
    }

    if (member.profile_picture_front) {
      const profilePictureFrontSignedUrl = await storageService.get(
        member.profile_picture_front
      );
      setProfilePictureFront(profilePictureFrontSignedUrl);
    }
  };
  useEffect(() => {
    loadProfileImage();
  }, []);

  const isExpanded = expandedRow !== -1;

  return (
    <>
      <EditCattleModalV2
        handleRemoveGps={handleRemoveGpsData}
        handleAlertable={{ on: handleTurnOnAlerts, off: handleTurnOffAlerts }}
        isAlertable={member.is_alertable}
        handleLoadNewSensor={(sensorName: string) =>
          handleLoadNewSensor(sensorName)
        }
        editIsLoading={editIsLoading}
        memberTagId={member?.tag_id ?? ""}
        memberETagId={member?.e_tag_id ?? ""}
        handleEditMember={handleEditMember}
        isVisible={editModal.isVisible}
        setIsVisible={editModal.setIsVisible}
        profilePicture={profilePicture}
        profilePictureFront={profilePictureFront}
      />
      {profilePictureModal.isVisible && member.profile_picture ? (
        <MemberProfilePicturesModal
          profilePicture={profilePicture}
          profilePictureFront={profilePictureFront}
          onClose={() => {
            profilePictureModal.setIsVisible(false);
          }}
          member={member}
        />
      ) : null}

      {paddockAssignmentModal.isVisible && (
        <Modal onClose={() => paddockAssignmentModal.setIsVisible(false)}>
          <Historic.Main
            onAdd={() => console.log("adding")}
            onClose={() => paddockAssignmentModal.setIsVisible(false)}
          >
            <Historic.Title>Potreros</Historic.Title>
            {paddockAssignmentEvents ? (
              <TableV2
                className="mt-4"
                columns={Historic.paddocksTableConstants()}
                items={paddockAssignmentEvents}
              />
            ) : null}
          </Historic.Main>
        </Modal>
      )}

      <main>
        {sensorPairingModal.isVisible && (
          <Modal onClose={() => sensorPairingModal.setIsVisible(false)}>
            <Historic.Main
              onAdd={() => console.log("adding")}
              onClose={() => sensorPairingModal.setIsVisible(false)}
              displayButtons={false}
            >
              <Historic.Title>Sensores</Historic.Title>
              {sensorPairingEvents ? (
                <TableV2
                  className="mt-4"
                  columns={Historic.sensorPairingTableConstants()}
                  items={sensorPairingEvents}
                />
              ) : null}
            </Historic.Main>
          </Modal>
        )}
        <MainLayoutDelimiter className="py-10">
          <p className="text-2xl font-medium text-[#09090B]">
            {" "}
            {t("admin.cattlePage.information.cattle")}
          </p>
          <section className="mt-10">
            <div className="flex items-stretch justify-between gap-6 w-full">
              <section className="p-6 rounded-xl bg-white flex flex-col items-start gap-5 w-full">
                <div className="w-full flex items-center justify-between">
                  <p className="text-lg font-medium">
                    {t("admin.cattlePage.profile")}
                  </p>
                  {user?.role === UserRole.SUPERUSER ? (
                    <button
                      className="font-medium text-sm py-2 px-3 cursor-pointer bg-transparent rounded-md border border-[#E4E4E7]"
                      onClick={() => editModal.setIsVisible(true)}
                    >
                      {t("admin.cattlePage.editProfile.title")}
                    </button>
                  ) : null}
                </div>
                <div className="border-t border-[#E4E4E7] w-full"></div>
                <div className="flex items-center justify-start gap-10 w-full">
                  <MemberProfilePicture
                    member={member}
                    className="m-0 !flex-shrink-0"
                    handleProfileImageOnClick={() => {}}
                    profilePicture={profilePicture}
                  />
                  <div className="w-full flex flex-col justify-start">
                    <div className="flex flex-col items-start">
                      <div className="flex mt-1 items-center gap-2">
                        <span className="text-gray-500">EID: </span>
                        <span className="font-semibold">{member.tag_id}</span>
                      </div>
                      <button
                        className="py-2 px-3 rounded-md bg-[#F4F4F5] cursor-pointer text-sm font-semibold mt-4"
                        onClick={handleProfileImageOnClick}
                      >
                        {t("admin.cattlePage.information.viewGallery")}
                      </button>
                    </div>
                    <div className="w-full flex items-start justify-between mt-10">
                      <div className="flex flex-col items-start gap-1">
                        <p className="text-sm font-medium text-[#64748B]">
                          {t("admin.cattlePage.information.birthDate")}
                        </p>
                        <p className="text-base font-medium">
                          {moment(member.birth_date).format("YYYY/MM/DD")}
                        </p>
                      </div>
                      <div className="flex flex-col items-start gap-1">
                        <p className="text-sm font-medium text-[#64748B]">
                          {t("admin.cattlePage.information.breed")}
                        </p>
                        <p className="text-base font-medium">{member.breed}</p>
                      </div>
                      <div className="flex flex-col items-start gap-1">
                        <p className="text-sm font-medium text-[#64748B]">
                          {t("admin.cattlePage.information.cattle")}
                        </p>
                        <p className="text-base font-medium">
                          {member.member_type}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="p-6 rounded-xl bg-white flex flex-col items-start gap-7 w-full">
                <div className="w-full flex items-center justify-between">
                  <p className="text-lg font-medium">
                  
                    {t("admin.cattlePage.information.location")}
                  </p>
                  <p className="text-sm">
                    
                    {currentFieldName === "outside"
                      ? t("admin.cattlePage.information.outside")
                      : t("admin.cattlePage.information.onField", {
                          field:currentFieldName,
                        })}
                  </p>
                </div>
                <div className="border-t border-[#E4E4E7] w-full"></div>
                <EnhancedMap
                  styleOptions={{ rounded: true }}
                  geoJsonData={{
                    ...geoJsonData,
                  }}
                  tiles={
                    ndviFilter
                      ? nvdiData.map((field) =>
                          field.agro ? field.agro[0].tile.ndvi : ""
                        )
                      : undefined
                  }
                  ndviOptions={{
                    onClick: handleNDVIFilter,
                    value: ndviFilter,
                    disabled: !nvdiData.some(
                      (field) =>
                        field.agro && field.agro[0] && field.agro[0].tile.ndvi
                    ),
                  }}
                />
              </div>
            </div>
          </section>
          <CattleInfoTabs
            member={member}
            alerts={alerts}
            className="mt-5"
            timeline={timeline}
            deltaActivityV3={deltaActivityV3}
            contactWithBullAggrEvents={contactWithBullAggrEvents}
            contactWithCowAggrEvents={contactWithCowAggrEvents}
            dailyBullJumps={dailyBullJumps}
          />
          <CattleInformation
            className={"mt-8"}
            selectedOption={selectedInformationOption}
            setSelectedOption={setSelectedInformationOption}
            memberWeight={member?.weight ?? 0}
            mainContent={
              <div>
                {selectedInformationOption === "vaccines" ? (
                  <>
                    {vaccinationEvents && !isExpanded ? (
                      <TableV2
                        columns={Historic.vaccinationTableConstants()}
                        items={vaccinationEvents}
                        renderTableRow={false}
                      >
                        {vaccinationEvents.map((vaccinationEvent) => (
                          <TableRow
                            key={vaccinationEvent.id}
                            onClick={() => handleRowOnClick(vaccinationEvent)}
                          >
                            {Historic.vaccinationTableConstants().map(
                              (col, key) => (
                                <td key={key}>
                                  {col.render(vaccinationEvent)}
                                </td>
                              )
                            )}
                          </TableRow>
                        ))}
                      </TableV2>
                    ) : null}
                    {isExpanded ? (
                      <section>
                        <ArrowBackIcon
                          className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                          onClick={handleRowOnClose}
                        />
                        {expandedEvent?.memberEvent ? (
                          <>
                            <Historic.Attribute
                              label={t(
                                `admin.cattlePage.attributes.vaccines.VACCINE`
                              )}
                              value={expandedEvent.memberEvent.value}
                            />
                            <Historic.Attribute
                              label={t(
                                `admin.cattlePage.attributes.vaccines.DATE`
                              )}
                              value={moment
                                .unix(expandedEvent.memberEvent.ts)
                                .format("DD/MM/YYYY")}
                            />
                          </>
                        ) : null}
                        {expandedEvent?.memberEventAttributes.map(
                          ({ attribute, value }) => (
                            <Historic.Attribute
                              label={t(
                                `admin.cattlePage.attributes.vaccines.${attribute}`
                              )}
                              value={value}
                            />
                          )
                        )}
                      </section>
                    ) : null}
                  </>
                ) : null}
                {selectedInformationOption === "weight" ? (
                  <>
                    {weightEvents && !isExpanded ? (
                      <TableV2
                        columns={Historic.weightTableConstants()}
                        items={weightEvents}
                        renderTableRow={false}
                      >
                        {weightEvents.map((weightEvent) => (
                          <TableRow
                            key={weightEvent.id}
                            onClick={() => handleRowOnClick(weightEvent)}
                          >
                            {Historic.weightTableConstants().map((col, key) => (
                              <td key={key}>{col.render(weightEvent)}</td>
                            ))}
                          </TableRow>
                        ))}
                      </TableV2>
                    ) : null}
                    {isExpanded ? (
                      <section>
                        <ArrowBackIcon
                          className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                          onClick={handleRowOnClose}
                        />
                        {expandedEvent?.memberEvent ? (
                          <>
                            <Historic.Attribute
                              label={t(
                                `admin.cattlePage.attributes.weights.WEIGHT`
                              )}
                              value={expandedEvent.memberEvent.value}
                            />
                            <Historic.Attribute
                              label={t(
                                `admin.cattlePage.attributes.weights.DATE`
                              )}
                              value={moment
                                .unix(expandedEvent.memberEvent.ts)
                                .format("DD/MM/YYYY")}
                            />
                          </>
                        ) : null}
                        {expandedEvent?.memberEventAttributes.map(
                          ({ attribute, value }) => (
                            <Historic.Attribute
                              label={t(
                                `admin.cattlePage.attributes.weights.${attribute}`
                              )}
                              value={value}
                            />
                          )
                        )}
                      </section>
                    ) : null}
                  </>
                ) : null}
                {selectedInformationOption === "sensors" ? (
                  <>
                    {sensorPairingEvents ? (
                      <TableV2
                        className="mt-4"
                        columns={Historic.sensorPairingTableConstants()}
                        items={sensorPairingEvents}
                      />
                    ) : null}
                  </>
                ) : null}
                {member.member_type === "COW" &&
                  selectedInformationOption === "pregnancy" && (
                    <>
                      {pregnancyEvents && !isExpanded ? (
                        <TableV2
                          className="mt-4"
                          columns={Historic.pregnancyTableConstants()}
                          items={pregnancyEvents}
                          renderTableRow={false}
                        >
                          {pregnancyEvents.map((event) => (
                            <TableRow
                              key={event.id}
                              onClick={() => handleRowOnClick(event)}
                            >
                              {Historic.pregnancyTableConstants().map(
                                (col, key) => (
                                  <td key={key}>{col.render(event)}</td>
                                )
                              )}
                            </TableRow>
                          ))}
                        </TableV2>
                      ) : null}
                      {isExpanded ? (
                        <section>
                          <ArrowBackIcon
                            className="absolute top-4 left-4 cursor-pointer w-[24px] h-[24px] fill-current text-gray-400"
                            onClick={handleRowOnClose}
                          />
                          {expandedEvent?.memberEvent ? (
                            <>
                              <Historic.Attribute
                                label={t(
                                  `admin.cattlePage.attributes.pregnancy.PREGNANCY`
                                )}
                                value={expandedEvent.memberEvent.value}
                              />
                              <Historic.Attribute
                                label={t(
                                  `admin.cattlePage.attributes.pregnancy.DATE`
                                )}
                                value={moment
                                  .unix(expandedEvent.memberEvent.ts)
                                  .format("DD/MM/YYYY")}
                              />
                            </>
                          ) : null}
                          {expandedEvent?.memberEventAttributes.map(
                            ({ attribute, value }) => (
                              <Historic.Attribute
                                label={t(
                                  `admin.cattlePage.attributes.pregnancy.${attribute}`
                                )}
                                value={value}
                              />
                            )
                          )}
                        </section>
                      ) : null}
                    </>
                  )}
              </div>
            }
          />
        </MainLayoutDelimiter>
      </main>
    </>
  );
};

export { CattleMemberPage };
