import React, { ChangeEvent, FunctionComponent, useState } from "react";
// Context
import { useMember } from "../../../../../context/member";
// Components
import { Icon } from "../../../../../components/Icon";
import {
  Modal,
  useModal,
} from "../../../../../components/Modal/Modal.component";
import { Image } from "../../../../../components/Image/Image";
import Tab, { Tabs } from "../../../../../components/Tab";
import request from "../../../../../services/request";
import * as Shadcn from "../../../../../components/shadcn/Button";
import Switch from "../../../../../components/shadcn/Switch";
import { Input } from "../../../../../components/shadcn/Input";
import { useTranslation } from "react-i18next";

type Props = {
  handleAlertable: { on: () => void; off: () => void };
  isAlertable: boolean;
  handleRemoveGps: () => void;
  handleLoadNewSensor: (newSensor: string) => void;
  handleEditMember: (params: { [key: string]: string }) => void;
  editIsLoading: boolean;
  memberTagId: string;
  memberETagId: string;
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  profilePicture: string | undefined;
  profilePictureFront: string | undefined;
};

type AvailableTabs = "GALLERY" | "PAIR_SENSOR" | "EDIT_CARAVANA" | "CONFIG";

const EditCattleModalV2: FunctionComponent<Props> = ({
  handleLoadNewSensor,
  handleEditMember,
  editIsLoading,
  memberTagId,
  memberETagId,
  isVisible,
  setIsVisible,
  handleAlertable,
  isAlertable,
  handleRemoveGps,
  profilePicture,
  profilePictureFront,
}) => {
  const {
    state: { member, isLoading },
    dispatch: memberDispatch,
  } = useMember();
  const [selectedTab, setSelectedTab] = useState<AvailableTabs>("GALLERY");
  const [newSensor, setNewSensor] = useState<string>("");
  const [tagId, setTagId] = useState(memberTagId);
  const [eTagId, setETagId] = useState(memberETagId);
  const [alertable, setAlertable] = useState(isAlertable);
  const [firstPicture, setFirstPicture] = useState<File | null>(null);
  const [secondPicture, setSecondPicture] = useState<File | null>(null);

  const handleTagId = (e: ChangeEvent<HTMLInputElement>) => {
    setTagId(e.target.value);
  };

  const { t } = useTranslation();

  const handleETagId = (e: ChangeEvent<HTMLInputElement>) => {
    setETagId(e.target.value);
  };

  const handleNewSensor = (e: ChangeEvent<HTMLInputElement>) => {
    setNewSensor(e.target.value);
  };
  const onChange = (e: any, side: "base" | "front") => {
    let file = e.target.files[0];
    uploadFile(file, side);
    if (side === "base") {
      setFirstPicture(file);
    }
    if (side === "front") {
      setSecondPicture(file);
    }
  };

  const uploadFile = async (file: any, side: string) => {
    let formData = new FormData();
    formData.append("file", file);
    request({
      method: "POST",
      url: `/api/members/${member?.id}/update_profile_pic`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        side,
      },
    })
      .then((r) => {
        let result = window.confirm(
          "Upload successfull, refresh to see changes?"
        );
        if (result) {
          window.location.reload();
        }
      })
      .catch((err) => {
        alert("There was an error uploading the image");
      });
  };

  const onSwitchAlertable = () => {
    if (alertable) {
      handleAlertable.off();
      setAlertable(false);
    } else {
      handleAlertable.on();
      setAlertable(true);
    }
  };

  return (
    <>
      {isVisible ? (
        <Modal
          onClose={() => {
            setIsVisible(false);
          }}
        >
          <section className="bg-white p-6 rounded-lg min-w-[750px] min-h-[400px]">
            <header>
              <p className="font-semibold">{member?.tag_id}</p>
              <div className="min-w-[750px]">
                <Tabs>
                  <Tab
                    label={t("admin.cattlePage.editProfile.gallery.title")}
                    onClick={() => {
                      setSelectedTab("GALLERY");
                    }}
                    selected={selectedTab === "GALLERY"}
                  />
                  <Tab
                    label={t("admin.cattlePage.editProfile.pairSensor.title")}
                    onClick={() => {
                      setSelectedTab("PAIR_SENSOR");
                    }}
                    selected={selectedTab === "PAIR_SENSOR"}
                  />
                  <Tab
                    label={t("admin.cattlePage.editProfile.editCaravana.title")}
                    onClick={() => {
                      setSelectedTab("EDIT_CARAVANA");
                    }}
                    selected={selectedTab === "EDIT_CARAVANA"}
                  />
                  <Tab
                    label={t("admin.cattlePage.editProfile.config.title")}
                    onClick={() => {
                      setSelectedTab("CONFIG");
                    }}
                    selected={selectedTab === "CONFIG"}
                  />
                </Tabs>
              </div>
            </header>
            {selectedTab === "GALLERY" ? (
              <section className="mt-2 flex justify-around items-center">
                <div className="flex flex-col gap-4 items-center">
                  <p className="text-[#020617] font-medium">
                    {t("admin.cattlePage.editProfile.gallery.profilePicture")}
                  </p>

                  {profilePicture ? (
                    <Image
                      className=" mt-2 w-40 h-40 rounded-full"
                      imageLink={profilePicture}
                      alt={"profile picture"}
                    />
                  ) : (
                    <Icon iconName="user_icon" className="h-40 w-40 mb-4" />
                  )}
                  <div className="relative">
                    <input
                      type="file"
                      className="opacity-0 absolute w-[150px] h-[40px]"
                      onChange={(e) => onChange(e, "base")}
                      accept="image/*"
                    />
                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-gray-900 text-white hover:bg-gray-800 h-10 py-2 px-4"
                    >
                      <svg
                        className="mr-2 h-4 w-4"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                        <polyline points="17 8 12 3 7 8" />
                        <line x1="12" x2="12" y1="3" y2="15" />
                      </svg>
                      {secondPicture && secondPicture.name
                        ? secondPicture.name
                        : t("admin.cattlePage.editProfile.gallery.loadImage")}
                    </button>
                  </div>
                </div>
                <div className="flex flex-col gap-4 items-center">
                  <p className="text-[#020617] font-medium">
                    {t("admin.cattlePage.editProfile.gallery.frontPicture")}
                  </p>
                  {profilePictureFront ? (
                    <Image
                      className=" mt-2 w-40 h-40 rounded-full"
                      imageLink={profilePictureFront}
                      alt={"profile picture front"}
                    />
                  ) : (
                    <Icon
                      iconName="user_icon"
                      className="h-44 w-44 mb-[-8px]"
                    />
                  )}
                  <div className="relative">
                    <input
                      type="file"
                      className="opacity-0 absolute w-[150px] h-[40px]"
                      onChange={(e) => onChange(e, "front")}
                      accept="image/*"
                    />
                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-gray-900 text-white hover:bg-gray-800 h-10 py-2 px-4"
                    >
                      <svg
                        className="mr-2 h-4 w-4"
                        fill="none"
                        height="24"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                        <polyline points="17 8 12 3 7 8" />
                        <line x1="12" x2="12" y1="3" y2="15" />
                      </svg>
                      {secondPicture && secondPicture.name
                        ? secondPicture.name
                        : t("admin.cattlePage.editProfile.gallery.loadImage")}
                    </button>
                  </div>
                </div>
              </section>
            ) : null}
            {selectedTab === "PAIR_SENSOR" ? (
              <div className="mt-8 h-full relative">
                <form className="flex flex-col gap-4 justify-between items-end h-full w-full min-h-[230px]">
                  <div className="flex gap-2 flex-col w-full">
                    <p className="text-[#020617] text-sm font-medium">
                      Mac address:
                    </p>
                    <Input
                      className="border-[1px] border-solid border-black rounded-md w-[80%]"
                      onChange={handleNewSensor}
                      value={newSensor}
                    />
                  </div>
                  <Shadcn.Button
                    onClick={() => handleLoadNewSensor(newSensor)}
                    disabled={newSensor === ""}
                    isLoading={editIsLoading}
                    label={t("admin.cattlePage.editProfile.pairSensor.save")}
                  />
                </form>
              </div>
            ) : null}
            {selectedTab === "EDIT_CARAVANA" ? (
              <form className="flex flex-col gap-4 justify-start items-end h-full mt-8">
                <div className="flex flex-col gap-2 w-full">
                  <p className="text-[#020617] text-sm font-medium">
                    Caravana:
                  </p>
                  <Input
                    className="border-[1px] border-solid border-black rounded-md w-full"
                    onChange={handleTagId}
                    value={tagId}
                  />
                </div>

                <div className="flex flex-col gap-2 w-full mb-8">
                  <p className="whitespace-nowrap text-[#020617] text-sm font-medium">
                    E-caravana:
                  </p>
                  <Input
                    className="border-[1px] border-solid border-black rounded-md w-full"
                    onChange={handleETagId}
                    value={eTagId}
                  />
                </div>

                <Shadcn.Button
                  className={"max-w-[130px]"}
                  onClick={() =>
                    handleEditMember({ e_tag_id: eTagId, tag_id: tagId })
                  }
                  disabled={
                    (eTagId === "" || eTagId === memberETagId) &&
                    (tagId === "" || tagId === memberTagId)
                  }
                  isLoading={editIsLoading}
                  label={t("admin.cattlePage.editProfile.editCaravana.save")}
                />
              </form>
            ) : null}
            {selectedTab === "CONFIG" ? (
              <div className="flex w-full h-[200px] items-center justify-around">
                <div className="flex gap-4">
                  {alertable ? (
                    <p>{t("admin.cattlePage.editProfile.config.turnOff")}</p>
                  ) : (
                    <p>{t("admin.cattlePage.editProfile.config.turnOn")}</p>
                  )}
                  <Switch
                    onChange={onSwitchAlertable}
                    initialState={isAlertable}
                  />
                </div>
                <Shadcn.Button
                  onClick={handleRemoveGps}
                  label={t("admin.cattlePage.editProfile.config.deleteGPSData")}
                />
              </div>
            ) : null}
          </section>
        </Modal>
      ) : null}
    </>
  );
};

export default EditCattleModalV2;
