import classNames from "classnames";
import React, { FunctionComponent, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  mainContent: ReactNode;
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  memberWeight: number;
};

type OptionProps = {
  option: { label: string; value: string };
  isSelected: boolean;
  selectOption: (value: string) => void;
};

const InformationOption: FunctionComponent<OptionProps> = ({
  option,
  isSelected,
  selectOption,
}) => {
  return (
    <li
      onClick={() => selectOption(option.value)}
      className={classNames(
        "text-[#09090B] w-full font-medium py-1.5 px-4 focus:bg-[#F4F4F5] cursor-pointer",
        isSelected ? "bg-[#F4F4F5] rounded-md" : null
      )}
    >
      {option.label}
    </li>
  );
};

export const CattleInformation: FunctionComponent<Props> = ({
  className,
  mainContent,
  selectedOption,
  setSelectedOption,
  memberWeight,
}) => {
  const { t } = useTranslation();

  const options = [
    { label: t("admin.cattlePage.information.vaccines"), value: "vaccines" },
    {
      label:
        memberWeight > 0
          ? t("admin.cattlePage.information.weight.titleWithValue", {
              weightValue: memberWeight,
            })
          : t("admin.cattlePage.information.weight.simpleTitle"),
      value: "weight",
    },
    { label: t("admin.cattlePage.information.sensors"), value: "sensors" },
    { label: t("admin.cattlePage.information.pregnancy"), value: "pregnancy" },
  ];

  return (
    <div
      className={classNames(
        "flex flex-col w-full pt-4 p-6 rounded-lg border bg-card text-card-foreground",
        className
      )}
    >
      <section className="pb-6 text-left border-b-[solid] border-b-[1px] border-b-[#E4E4E7]">
        <h3 className="text-[#18181B] font-medium text-lg">
          {t("admin.cattlePage.information.title")}
        </h3>
      </section>
      <section className="flex w-full">
        <ul className="flex flex-col min-w-[250px] gap-4 py-3">
          {options.map((x, i) => (
            <InformationOption
              key={i}
              option={x}
              isSelected={x.value === selectedOption}
              selectOption={setSelectedOption}
            />
          ))}
        </ul>
        <div className="w-5/6 flex justify-center items-start py-3 px-4 h-[300px] overflow-y-auto">
          {mainContent}
        </div>
      </section>
    </div>
  );
};
