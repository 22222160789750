import React from "react";
import { FunctionComponent, useState } from "react";
import ActivityChartComponent from "../../pages/cattle/layout/ActivityChartContainer/ActivityChartComponent";
import classNames from "classnames";
import { AlertsPage } from "../../pages/alerts/layout/AlertsPage";
import { Alert } from "../../../types/alerts";
import { useAlerts } from "../../pages/alerts/AlertsPageContainer";
import { Timeline } from "../../pages/cattle/layout/components";
import { Chart } from "../../../lib/utils/chart";
import { MemberEvent } from "../../../lib/utils/member-event.utils";
import { Bar, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import DailyBullJumpsContainer from "../../pages/cattle/layout/DailyBullJumpsContainer/DailyBullJumps.Container";

type Props = {
  className?: string;
  member: MemberWithDetails;
  alerts: Alert[] | null | undefined;
  timeline: Timeline | null | undefined;
  deltaActivityV3: DailyActivity | null;
  contactWithCowAggrEvents: MemberEvent[] | null;
  contactWithBullAggrEvents: MemberEvent[] | null;
  dailyBullJumps: DailyActivityV2 | null;
};

const CattleInfoTabs: FunctionComponent<Props> = ({
  alerts,
  member,
  timeline,
  className,
  deltaActivityV3,
  contactWithCowAggrEvents,
  contactWithBullAggrEvents,
  dailyBullJumps,
}) => {
  const alertsH = useAlerts();
  const [activeTab, setActiveTab] = useState("activity");
  const { t } = useTranslation();
  return (
    <div className={classNames("w-full bg-white p-6 rounded-xl", className)}>
      <div className="grid grid-cols-5 p-2 bg-[#F4F4F5]  w-full mr-auto items-center justify-between rounded-md">
        <button
          className={`py-2 px-4 text-base font-normal text-center ${
            activeTab === "activity"
              ? "text-[#09090B] bg-white"
              : "text-[#71717A] bg-[#F4F4F5]"
          }`}
          onClick={() => setActiveTab("activity")}
        >
          {t("admin.cattlePage.chartOptions.activity")}
        </button>
        <button
          className={`py-2 px-4 text-base font-normal text-center ${
            activeTab === "alerts"
              ? "text-[#09090B] bg-white"
              : "text-[#71717A] bg-[#F4F4F5]"
          }`}
          onClick={() => setActiveTab("alerts")}
        >
          {t("admin.cattlePage.chartOptions.alerts")}
        </button>
        <button
          className={`py-2 px-4 text-base font-normal text-center ${
            activeTab === "timeline"
              ? "text-[#09090B] bg-white"
              : "text-[#71717A] bg-[#F4F4F5]"
          }`}
          onClick={() => setActiveTab("timeline")}
        >
          {t("admin.cattlePage.chartOptions.timeline")}
        </button>
        <button
          className={`py-2 px-4 text-base font-normal text-center whitespace-pre ${
            activeTab === "contacts"
              ? "text-[#09090B] bg-white"
              : "text-[#71717A] bg-[#F4F4F5]"
          }`}
          onClick={() => setActiveTab("contacts")}
        >
          {member.member_type === "BULL"
            ? t("admin.cattlePage.chartOptions.cowsContacts")
            : t("admin.cattlePage.chartOptions.bullContacts")}
        </button>
        {member.member_type === "BULL" ? (
          <button
            className={`py-2 px-4 text-base font-normal text-center whitespace-pre ${
              activeTab === "daily-bull-jumps"
                ? "text-[#09090B] bg-white"
                : "text-[#71717A] bg-[#F4F4F5]"
            }`}
            onClick={() => setActiveTab("daily-bull-jumps")}
          >
            {t("admin.cattlePage.cattleID.bullJumps")}
          </button>
        ) : null}
      </div>
      {activeTab === "activity" && deltaActivityV3 ? (
        <>
          {Object.keys(deltaActivityV3).length > 0 ? (
            <ActivityChartComponent
              labels={Object.keys(deltaActivityV3)}
              datasets={[
                {
                  label: "Activity",
                  data: Object.values(deltaActivityV3),
                  backgroundColor: "rgba(75, 192, 192, 0.5)",
                },
              ]}
            />
          ) : (
            <div className="w-full min-h-[300px] flex justify-center items-center">
              <p className=" text-lg">
                {t("admin.cattlePage.chartOptions.emptyState")}
              </p>
            </div>
          )}
        </>
      ) : null}
      {activeTab === "alerts" && alerts ? (
        <AlertsPage
          className="!bg-transparent !px-0 !pt-10"
          title={alertsH.title}
          noAlertsMessage={alertsH.noAlertsMessage}
          alertsTableColumns={alertsH.alertsTableColumns}
          alerts={alerts}
        />
      ) : null}
      {activeTab === "timeline" && timeline ? (
        <Timeline className="mt-4 p-4" timeline={timeline} />
      ) : null}
      {activeTab === "contacts" &&
      member.member_type === "BULL" &&
      contactWithCowAggrEvents ? (
        <>
          {contactWithCowAggrEvents.length > 0 ? (
            <Bar
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
              className="mt-4 max-h-[350px]"
              data={{
                labels: Chart.getAtoBDates().formated,
                datasets: MemberEvent.generateContactsWithCattleDataset(
                  contactWithCowAggrEvents,
                  Chart.getAtoBDates().timestamps
                ),
              }}
            />
          ) : (
            <div className="w-full min-h-[300px] flex justify-center items-center">
              <p className=" text-lg">
                {t("admin.cattlePage.chartOptions.emptyState")}
              </p>
            </div>
          )}
        </>
      ) : null}
      {member.member_type === "BULL" &&
      dailyBullJumps &&
      activeTab === "daily-bull-jumps" ? (
        <>
          {Object.keys(dailyBullJumps).length > 0 ? (
            <div className="mt-8">
              <DailyBullJumpsContainer dailyBullJumps={dailyBullJumps} />
            </div>
          ) : (
            <div className="w-full min-h-[300px] flex justify-center items-center">
              <p className=" text-lg">
                {t("admin.cattlePage.chartOptions.emptyState")}
              </p>
            </div>
          )}
        </>
      ) : null}
      {activeTab === "contacts" &&
      member.member_type === "COW" &&
      contactWithBullAggrEvents ? (
        <>
          {contactWithBullAggrEvents.length > 0 ? (
            <Line
              className="mt-4 max-h-[350px]"
              options={{
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                  point: {
                    radius: 7,
                  },
                },
              }}
              data={{
                labels: Chart.getAtoBDates().formated,
                datasets: MemberEvent.generateContactsWithCattleDataset(
                  contactWithBullAggrEvents,
                  Chart.getAtoBDates().timestamps
                ),
              }}
            />
          ) : (
            <div className="w-full min-h-[300px] flex justify-center items-center">
              <p className=" text-lg">
                {t("admin.cattlePage.chartOptions.emptyState")}
              </p>
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default CattleInfoTabs;
