import React, { FC, useEffect, useState } from "react";

import { Modal } from "../../../../../components/Modal/Modal.component";
import { Image } from "../../../../../components/Image/Image";

import { useServices } from "../../../../../services/service-providers/service-provider";

interface Props {
  member: Member;
  onClose: () => void;
  profilePicture: string | undefined;
  profilePictureFront: string | undefined;
}

const MemberProfilePicturesModal: FC<Props> = ({ member, onClose,profilePicture,profilePictureFront }) => {


  return (
    <Modal
      onClose={() => {
        onClose();
      }}
    >
      <main className="grid gap-2 bg-white p-4 rounded">
        {profilePicture ? (
          <Image
            className="w-full max-w-[600px] h-full max-h-[400px] object-cover"
            imageLink={profilePicture}
            alt={member.tag_id}
          />
        ) : null}
        {profilePictureFront ? (
          <Image
            className="w-full max-w-[600px] h-full max-h-[400px]"
            imageLink={profilePictureFront}
            alt={`${member.tag_id}_front`}
          />
        ) : null}
      </main>
    </Modal>
  );
};

export default MemberProfilePicturesModal;
